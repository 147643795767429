/**
 * Delimiter from a git branch name to parse the parent version it belongs to.
 * @example
 * `1.0.0_my-branch-name`
 */
export const BRANCH_SPLIT_KEY = '_';

/**
 * Whether the provided string contains a valid version and valid branch name.
 */
export const hasVersionBranch = (str = ''): boolean => {
  return str.includes(BRANCH_SPLIT_KEY);
};

/**
 * Parses the provided string and returns a hash with prefixless `version` and
 * `branch` name segments.
 * @example
 * ```ts
 * parseVersionBranch('1.2.0')
 * // => { version: '1.2.0', branch: '' }
 * parseVersionBranch('1.2.0_cool-branch')
 * // => { version: '1.2.0', branch: '1.2.0_cool-branch' }
 * ```
 */
export const parseVersionBranch = (
  str = '',
): {
  version: string;
  branch: string;
} => {
  const normalizedStr = str.trim();
  const [version, ...rest] = normalizedStr.split(BRANCH_SPLIT_KEY);
  return { version, branch: rest.length ? normalizedStr : '' };
};

/**
 * Determines if the provided string is a valid version name with a `v` prefix.
 */
export function isVersion(str: string): boolean {
  return !!str.trim().match(/^v([0-9]+)(?:\.([0-9]+))?(?:\.([0-9]+))?(-.*)?$/);
}

/**
 * Determines if the provided string is a valid branch name with a `v` prefix.
 */
export function isBranch(str: string): boolean {
  const { version, branch } = parseVersionBranch(str);
  /** @todo Remove temporary `!!branch` override and actually validate the
   * branch name to match our amazing regex handcrafted by Julia ⭐️
   * @link https://linear.app/readme-io/issue/RM-12432/split-version-and-branch-identifier-update-regexes
   */
  const isValidBranch = !!branch || /some-future-regex/.test(branch);
  return isVersion(version) && !!isValidBranch;
}
